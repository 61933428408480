<template>
    <div class="top-items">
        <h3>{{ title }}</h3>
        <ul>
            <li v-for="item in items" :key="item.name">
                <strong>{{ item.name }}</strong>: {{ item.items }} items
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: ['items', 'title']
};
</script>

<style scoped>
.top-items {
    margin-top: 20px;
}

.top-items ul {
    list-style-type: none;
    padding-left: 0;
    display: block;
}

.top-items li {
    margin: 5px 0;
    display: block;
}
</style>
