<template>
  <div>

    <!-- Loader -->
    <LoaderDiv :isLoading="loading" loaderText="Chargement en cours, veuillez patienter..." />

    <h2>Liste des offres</h2>
    <div>
      Cette écran affiche la liste des offres que vous suivez ou que vous avez suivi.
    </div>
    <div class="count">Vous avez {{ totalOffers }} offres.</div>

    <div class="main-content-offers" ref="mainContentOffers" @scroll="handleScroll">

      <!-- Filtres -->
      <div class="filters">
        <!-- Filtre par ID -->
        <label>Filtrer par ID :</label>
        <input v-model="filters.id" placeholder="Rechercher par ID" class="input-filter" @input="filterOffers">

        <!-- Filtre par statut -->
        <label>Filtrer par statut :</label>
        <select v-model="filters.status" class="input-filter" @change="filterOffers">
          <option value="">Tous les statuts</option>
          <option value="owned">Acheté</option>
          <option value="notowned">Perdus</option>
        </select>
      </div>

      <table>
        <thead>
          <tr>
            <th>Détail</th>
            <th width="140">Date de fin</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody ref="offersTable">
          <tr v-for="offer in offers" :key="offer._key">
            <td>
              <a href="#" @click.prevent="showDetails(offer)"> <img
                  :src="cdnUrl + '/images/' + offer._key + '/' + offer._key + '_thumbnail.jpg'" width="50px"
                  class="thumbnail"></a>
              {{ offer.title }}<br />
              <a href="#" @click.prevent="showDetails(offer)">{{ offer._key }}</a> / <a target="_blank"
                :href="'https://page.auctions.yahoo.co.jp/jp/auction/' + offer._key">Yahoo !</a> / <a target="_blank"
                :href="'https://zenmarket.jp/fr/auction.aspx?itemCode=' + offer._key">Zenmarket</a>
              <br>
            </td>
            <td>{{ new Date(offer.endtime).toLocaleString()}}</td>
            <td>
              <button @click="parse(offer)">Rafraichir</button>
              &nbsp;
              <button @click="setOwned(offer)" :disabled="loadingIndex === offer._key">
                <span v-if="loadingIndex === offer._key">Chargement...</span>
                <span v-else>
                  {{ offer.owns ? 'Perdu' : 'Acheté' }}
                </span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <OfferDetailPopup :isVisible="isModalVisible" @close="isModalVisible = false" :offer="selectedOffer"
      :loadingPopup="loadingPopup" />

  </div>
</template>

<script>
import axios from "axios";
import config from "@/config/config.js";
import { isTokenExpired } from "../../auth";
import LoaderDiv from "./../LoaderDiv.vue";
import OfferDetailPopup from "./OfferDetailPopup.vue";

export default {
  components: {
    LoaderDiv,
    OfferDetailPopup
  },
  name: "OffersList",
  data() {
    return {
      loadingIndex: null,
      offers: [],
      loading: false,
      loadingPopup: false,
      selectedOffer: {},
      isModalVisible: false,
      currentPage: 0,
      limit: config.itemsLimit,
      cdnUrl: config.cdnUrl,
      totalOffers: 0,
      allOffersLoaded: false,
      filters: {
        id: '',
        status: ''
      },

    };
  },

  async mounted() {
    const token = localStorage.getItem("access_token");
    if (isTokenExpired(token)) {
      this.$router.push("/");
    }
    await this.loadOffers();
  },

  // beforeUnmount() {
  //   // Retirer l'écouteur lors de la destruction du composant
  //   const mainContent = this.$refs.mainContent;
  //   if (mainContent) {
  //     mainContent.removeEventListener("scroll", this.handleScroll);
  //   }
  // },

  methods: {
    async filterOffers() {
      this.currentPage = 0;
      this.allOffersLoaded = false;
      this.offers = [];
      this.selectedOffer = {};
      await this.loadOffers()
    },

    async setOwned(offer) {
      this.loadingIndex = offer._key;
      let offer_status = !offer.owns;
      if (offer_status) {
        await axios.post(
          `${config.apiUrl}/offers/${offer._key}/owns`
        );
      } else {
        await axios.delete(
          `${config.apiUrl}/offers/${offer._key}/owns`
        );
      }
      offer.owns = offer_status;
      this.loadingIndex = null;
    },

    async loadOffers() {

      // Ensure not all offers and not loading in progress
      if (this.loading || this.allOffersLoaded) return;

      // Cookie session
      const token = localStorage.getItem("access_token");
      if (isTokenExpired(token)) {
        this.$router.push("/");
      }

      // Ajout des filtres aux paramètres de la requête
      const params = new URLSearchParams();
      if (this.filters.id) {
        params.append('id', this.filters.id);
      }
      if (this.filters.status) {
        params.append('owned', this.filters.status == 'owned' ? "True" : "False");
      }

      try {

        // Activer le loader
        this.loading = true;

        const response = await axios.get(
          `${config.apiUrl}/offers?page=${this.currentPage}&limit=${this.limit}&${params.toString()}`
        );

        // Append new results
        this.offers.push(...response.data.result);

        // Get statistics
        this.totalOffers = response.data.total_items;
        this.currentPage += 1;

        // Vérifier si tous les éléments ont été chargés
        if (this.offers.length >= this.totalOffers) {
          this.allOffersLoaded = true;
        }

      } catch (error) {
        console.error("Erreur lors de la récupération des offress:", error);
      } finally {
        // Désactiver le loader
        this.loading = false;
      }
    },

    // Gestion du scroll infini
    handleScroll() {
      const mainContent = this.$refs.mainContentOffers;
      const offersTable = this.$refs.offersTable;

      if (!mainContent || !offersTable || this.allOffersLoaded) return;

      // Vérifier si l'utilisateur a atteint la fin du tableau (tbody)
      const tableBottomReached =
        mainContent.scrollTop + mainContent.clientHeight >= offersTable.clientHeight;

      if (tableBottomReached) {
        this.loadOffers(); // Charger plus d'éléments quand on arrive à la fin du tableau
      }
    },

    async showDetails(offer) {
      // Affiche le loader
      this.loadingPopup = true;
      this.isModalVisible = true;

      try {
        // Appel à l'API pour récupérer les détails de l'élément
        const response = await axios.get(
          `${config.apiUrl}/offers/${offer._key}`
        );

        // Met à jour selectedOffer avec les données récupérées de l'API
        this.selectedOffer = response.data;
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des détails de l'annonce :",
          error
        );
        // Gérer les erreurs ici (afficher un message d'erreur par exemple)
      } finally {
        // Désactiver le loader après la récupération des données
        this.loadingPopup = false;
      }
    },

    async parse(offer) {
      // Affiche le loader
      this.loadingPopup = true;
      this.isModalVisible = false;

      try {
        if (offer.url === undefined) alert("L'url de cet offre n'est pas valide");
        else {
          // Appel à l'API pour récupérer les détails de l'élément
          await axios.get(
            `${config.apiUrl}/offers/parse?url=${offer.url}&notify=true`
          );
        }
      } catch (error) {
        console.error(
          "Erreur lors de la l'actualisation de l'offre :",
          error
        );
      } finally {
        this.loadingPopup = false;
      }
    },
  },
};
</script>

<style scoped>
.filters {
  margin-top: 10px;
  margin-bottom: 5px;
}

.input-filter {
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.count {
  margin-top: 10px;
  font-size: 11pt;
  font-weight: bold
}

.thumbnail {
  float: left;
  margin: 0px 15px 0px 0px;
  max-height: 37px;
}

.offers-list-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* Prendre toute la hauteur de la fenêtre */
}

.ad-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  margin: 10px 0;
}

.main-content-offers {
  width: auto;
  height: calc(100vh - 160px);
  /* flex-grow: 1; */
  /* Ajustez la hauteur pour permettre le défilement */
  overflow-y: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 0px;
}

th,
td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  /* background-color: #f2f2f2; */
  font-weight: normal;
  background-color: #3498db;
  color: white;
}

button {
  padding: 6px 12px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #ff1a1a;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  text-align: center;
  font-size: 18px;
  color: #3498db;
}
</style>
