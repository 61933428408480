<template>
    <div class="dashboard">
        <!-- KPI Section -->
        <div class="kpi-section">
            <div class="kpi-item">
                <h3>Offres suivies</h3>
                <p>{{ dashboardData.orders.total }}</p>
            </div>
            <div class="kpi-item">
                <h3>Offres gagnées</h3>
                <p>{{ dashboardData.items.total }}</p>
            </div>
            <div class="kpi-item">
                <h3>Argent dépensé</h3>
                <p>{{ dashboardData.money.spent }}€</p>
            </div>
        </div>
        <div class="kpi-section">
            <div class="kpi-item">
                <h3>Objets détenus</h3>
                <p>{{ dashboardData.items.total }}</p>
            </div>
            <div class="kpi-item">
                <h3>Objets vendus</h3>
                <p>{{ dashboardData.items.total }}</p>
            </div>
            <div class="kpi-item">
                <h3>Argent gagné</h3>
                <p>{{ dashboardData.money.spent }}€</p>
            </div>
        </div>

        <!-- Charts Section -->
        <div class="charts-section">
            <div class="chart-item">
                <h3>Jeux</h3>
                <pie-chart :data="pieChartData.games"></pie-chart>
            </div>
            <div class="chart-item">
                <h3>Modèles</h3>
                <pie-chart :data="pieChartData.models"></pie-chart>
            </div>
        </div>

        <div class="tops-section">
            <top-items :items="dashboardData.tops.models" title="Top Models"></top-items>
            <top-items :items="dashboardData.tops.games" title="Top Games"></top-items>
            <top-items :items="dashboardData.tops.devices" title="Top Devices"></top-items>
        </div>
    </div>
</template>

<script>


// Importer le composant TopItems pour les Top Models et Games
import TopItems from './charts/TopItems.vue';

export default {
    components: {
        TopItems
    },
    data() {
        return {
            dashboardData: {
                orders: {
                    total: 123
                },
                items: {
                    total: 83
                },
                money: {
                    spent: 1255
                },
                tops: {
                    models: [
                        { name: 'Gamecube', items: 20 },
                        { name: 'Super Famicom', items: 15 },
                        { name: 'Famicom', items: 10 }
                    ],
                    games: [
                        { name: 'Zelda', items: 5 },
                        { name: 'Mario Party 5', items: 15 },
                        { name: 'Street Fighters', items: 2 }
                    ],
                    devices: [
                        { name: 'Manette', items: 50 },
                        { name: 'Alimentation', items: 15 },
                        { name: 'Autre', items: 2 }
                    ]
                }
            },
            pieChartData: {
                models: [
                    ['Gamecube', 20],
                    ['Super Famicom', 15],
                    ['Famicom', 10],
                ],
                games: [
                    ['Zelda', 20],
                    ['Mario Party 5', 15],
                    ['Street Fighters', 10],
                ],

                devices: [
                    { label: 'Zelda', value: 5 },
                    { label: 'Mario Party 5', value: 15 },
                    { label: 'Street Fighters', value: 2 }
                ]
            }
        };
    }
};
</script>

<style scoped>
.dashboard {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.kpi-section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.kpi-item {
    flex: 1;
    background: #f4f4f4;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
}

.charts-section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.chart-item {
    flex: 1;
    background: #f4f4f4;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
}

.tops-section {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}
</style>
