import config from "./config/config";
import axios from "axios";

export async function filterList(listName, filterName, filterValue) {
    try {
        let filter = "";
        if(filterName != undefined && filterValue != undefined)
            filter = "?" + filterName + "=" + filterValue;
        const response = await axios.get(config.apiUrl + '/lists/' + listName + filter)
        if (response.status > 300) {
            alert("Une erreur s'est produite.");
        }
        return response.data;

    } catch (error) {
        console.error('Erreur :', error);
        alert("Une erreur s'est produite.");

        return null;
    }
}