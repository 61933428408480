<template>

    <div>
        <!-- Contenu de la page -->
        <LoaderDiv :isLoading="loading"  loaderText="Chargement en cours, veuillez patienter..." />
    </div>


    <h2>Gérer mon profile</h2>
    <div>Mettez à jour vos informations de profile</div>  
    <form @submit.prevent="updateProfile">
      <!-- <h2>Informations de profile</h2> -->
  
      <label for="id">ID</label>
      <input type="text" v-model="user.id" disabled />
  
      <label for="firstname">Prénom</label>
      <input type="text" v-model="user.firstname" />
  
      <label for="lastname">Nom</label>
      <input type="text" v-model="user.lastname" />
  
      <label for="email">Email</label>
      <input type="email" v-model="user.email" />

      <label for="channel">Canal de notification</label>
      <input type="text" v-model="user.channel" />      
  
      <button type="submit">Mettre à jour</button>
    </form>
  </template>
  
  <script>
  import axios from 'axios';
  import { isTokenExpired } from '../auth';
  import LoaderDiv from './LoaderDiv.vue'; // Chemin vers votre composant Loader

  export default {
    components: {
      LoaderDiv
  },
    name: 'ProfileForm',  // Ici, le nom du composant doit être multi-mots    
    data() {
      return {
        user: {},
        loading: true
      };
    },
    async created() {
      const token = localStorage.getItem('access_token');
      if (isTokenExpired(token)) {
        // Rediriger vers la page de login si le token est expiré
        this.$router.push('/');
      }

      try {
        // Activer le loader
        this.loading = true;

        const response = await axios.get('https://collection.prexens.com/user');
        this.user = response.data;

      } catch (error) {
        console.error("Erreur lors de la récupération des jobs:", error);
      } finally {
        // Désactiver le loader
        this.loading = false;
      }
    },    
    methods: {
      async updateProfile() {

        const token = localStorage.getItem('access_token');
        const user = {
          "email" : this.user.email,
          "firstname" : this.user.firstname,
          "lastname" : this.user.lastname,
          "channel" : this.user.channel
        }
        try {
          await axios.post(`https://collection.prexens.com/user`, user, {
            headers: { Authorization: `Bearer ${token}` }
          });

        } catch (error) {
            console.error("Erreur lots de la mise à jour du profile:", error);
            alert("Erreur lors de la mise à jour du profile.");
         }
      }
    }
  };
  </script>
  
  <style scoped>
  form {
    /* max-width: 500px; */
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  form h2 {
    margin-bottom: 20px;
    /* font-size: 24px; */
  }
  
  form label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
  }
  
  form input {
    width: 100%;
    padding: 10px;
    padding-right:0px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  form button {
    width: 100%;
    padding: 10px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  form button:hover {
    background-color: #2980b9;
  }
  </style>
  