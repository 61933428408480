<template>
    <div v-if="isVisible" class="popup">
        <div class="popup-content">
            <h2>Détails de l'objet</h2>
            <div class="form-section">
                <h3>Ajouter un nouvel objet</h3>

                <label>Type :</label>
                <input v-model="localItem.type" type="radio" id="console" name="type" value="console" checked
                    class="checkbox-field" />
                <label for="console">Console</label>
                <input v-model="localItem.type" type="radio" id="game" name="type" value="game"
                    class="checkbox-field" />
                <label for="game">Jeu</label>
                <input v-model="localItem.type" type="radio" id="device" name="type" value="device"
                    class="checkbox-field" />
                <label for="device">Périphérique</label><br>

                <label>Marque :</label>
                <select v-model="localItem.brand" class="input-field" :class="{ 'is-invalid': errors.brand }">
                    <option v-for="brand in brands" :key="brand.id" :value="brand.name">{{ brand.name }}</option>
                    <option value="new">+ Ajouter une nouvelle marque</option>
                </select>
                <span v-if="errors.brand" class="error-message">{{ errors.brand }}</span>
                <div v-if="localItem.brand === 'new'" class="form-group">
                    <label>Nouvelle Marque :</label>
                    <input v-model="newBrand" placeholder="Entrer une nouvelle marque" class="input-field">
                </div>

                <label>Dénomination :</label>
                <select v-model="localItem.model" class="input-field" :class="{ 'is-invalid': errors.model }">
                    <option v-for="model in models" :key="model.id" :value="model.name">{{ model.name }}</option>
                    <option value="new">+ Ajouter une nouvelle dénomination</option>
                </select>
                <span v-if="errors.model" class="error-message">{{ errors.model }}</span>
                <div v-if="localItem.model === 'new'" class="form-group">
                    <label>Nouvelle Dénomination :</label>
                    <input v-model="newModel" placeholder="Entrer une nouvelle dénomination" class="input-field">
                </div>

                <label>Origine :</label>
                <select v-model="localItem.country" class="input-field" :class="{ 'is-invalid': errors.country }">
                    <option v-for="country in countries" :key="country.id" :value="country.name">{{ country.name }}
                    </option>
                </select>
                <span v-if="errors.country" class="error-message">{{ errors.country }}</span>

                <label>État :</label>
                <select v-model="localItem.condition" class="input-field">
                    <option v-for="condition in conditions" :key="condition.id" :value="condition.name">{{
                        condition.name }}</option>
                </select>

                <div v-if="localItem.type != 'game'">
                    <label>Titre :</label>
                    <input v-model="localItem.title" placeholder="Nom" class="input-field">
                </div>
                <div v-else>
                    <label>Nom :</label>
                    <input v-model="localItem.name" @input="filterGames" placeholder="Nom du jeu" class="input-field"
                        @keydown.down.prevent="navigateSuggestions('down')"
                        @keydown.up.prevent="navigateSuggestions('up')"
                        @keydown.enter.prevent="selectHighlightedGame" />
                    <div v-if="filteredGames.length" class="autocomplete-list">
                        <div v-for="(game, index) in filteredGames" :key="game.id" @click="selectGame(game.name)"
                            :class="{ 'highlighted': index === this.highlightedIndex }" class="autocomplete-item">
                            {{ game.name }}
                        </div>
                    </div>
                </div>

                <label>Description :</label>
                <textarea v-model="localItem.description" placeholder="Description de l'objet"
                    class="input-field"></textarea>

                <label for="sold">Vendu :</label>
                <input type="checkbox" id="sold" v-model="localItem.sold" class="checkbox-field"
                    @change="resetSoldPrice" />
                <div class="form-group">
                    <label for="soldPrice">Prix de vente :</label>
                    <input type="number" id="soldPrice" v-model="localItem.soldPrice" :disabled="!localItem.sold"
                        placeholder="Entrer le prix de vente" class="input-field" />
                </div>
                <button @click="saveItem" class="btn-add">Mettre à jour</button>&nbsp;
                <button @click="closePopup" class="btn-add">Fermer</button>
            </div>
        </div>
    </div>
</template>

<script>
import config from '@/config/config';
import {filterList} from '@/database';
import axios from "axios";

export default {
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        itemId: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            localItem: {},
            // items: [], // Liste des objets dans l'annonce
            brands: [], // Liste des marques récupérées depuis l'API
            models: [], // Liste des dénominations récupérées depuis l'API
            conditions: [], // Liste des états récupérés depuis l'API
            countries: [], // Liste des pays récupérés depuis l'API
            newBrand: '', // Nouvelle marque si ajoutée
            newBrandIndex: null, // Index où une nouvelle marque est ajoutée
            newModel: '', // Nouvelle dénomination si ajoutée
            newModelIndex: null, // Index où une nouvelle dénomination est ajoutée
            errors: {
                brand: null,
                model: null
            },
            games: [{ "id": 1, "name": "FIFA 21" }, { "id": 2, "name": "Call of Duty" }, { "id": 3, "name": "Assassin's Creed" }],
            filteredGames: [],
            highlightedIndex : -1
        };
    },
    watch: {
        itemId: {
            async handler(newItemId) {
                if (newItemId != null)
                    await this.loadItem(newItemId)
            },
            immediate: true
        }
    },
    methods: {
        async filterGames() {
            const query = this.localItem.name.toLowerCase();
            if (query === '') {
                this.filteredGames = [];
            } else {
               this.filteredGames = await filterList("games","name", query);
            }
            this.highlightedIndex = -1;
        }, 
        selectGame(gameName) {
            this.localItem.name = gameName;
            this.filteredGames = []; // Réinitialiser la liste filtrée
            this.highlightedIndex = -1;
        },        
        navigateSuggestions(direction) {
            console.log(direction)
            const suggestions = this.filteredGames;
            if (suggestions.length === 0) return;

            if (direction === 'down') {
                this.highlightedIndex = (this.highlightedIndex + 1) % suggestions.length;
            } else if (direction === 'up') {
                this.highlightedIndex = (this.highlightedIndex - 1 + suggestions.length) % suggestions.length;
            }

            console.log(this.highlightedIndex)
        },
        selectHighlightedGame() {
            if (this.highlightedIndex !== -1 && this.filteredGames.length > 0) {
                this.selectGame(this.filteredGames[this.highlightedIndex].name);
            }
        },                       
        async loadItem(item_key) {
            this.$emit('loading');
            try {
                const response = await axios.get(
                    `${config.apiUrl}/items/${item_key}`
                );
                this.localItem = response.data;

            } catch (error) {
                console.error(
                    "Erreur lors de la récupération des détails l'objet :",
                    error
                );
            } finally {
                this.$emit('loaded');
            }
        },
        resetSoldPrice() {
            if (!this.localItem.sold) {
                this.localItem.soldPrice = null;
            }
        },

        async fetchInitialData() {
            const [brandsRes, modelsRes, conditionsRes, countriesRes] = await Promise.all([
                axios.get(config.apiUrl + '/lists/brands'),
                axios.get(config.apiUrl + '/lists/models'),
                axios.get(config.apiUrl + '/lists/conditions'),
                axios.get(config.apiUrl + '/lists/countries')
            ]);
            this.brands = await brandsRes.data;
            this.models = await modelsRes.data;
            this.conditions = await conditionsRes.data;
            this.countries = await countriesRes.data;
        },
        checkIfNewBrand(index) {
            if (this.items[index].brand === 'new') {
                this.newBrandIndex = index;
            } else {
                this.newBrandIndex = null;
            }
        },
        checkIfNewModel(index) {
            if (this.items[index].model === 'new') {
                this.newModelIndex = index;
            } else {
                this.newModelIndex = null;
            }
        },
        async saveItem() {
            this.$emit('loading');
            console.log('Objets sauvegardés:', this.localItems);
            const payload = {
                items: [this.localItem]
            };

            // Si une nouvelle marque a été ajoutée
            if (this.newBrand) {
                await fetch('/objects/brands', {
                    method: 'POST',
                    body: JSON.stringify({ name: this.newBrand }),
                    headers: { 'Content-Type': 'application/json' }
                });
            }

            // Si une nouvelle dénomination a été ajoutée
            if (this.newModel) {
                await fetch('/objects/models', {
                    method: 'POST',
                    body: JSON.stringify({ name: this.newModel }),
                    headers: { 'Content-Type': 'application/json' }
                });
            }

            try {
                const response = await axios.post(config.apiUrl + '/items', payload, { headers: { 'Content-Type': 'application/json' } }) // Endpoint pour les marques
                if (response.status > 300) {
                    alert("Une erreur s'est produite lors de l'enregistrement de l'objet.");
                }
                this.closePopup();
            } catch (error) {
                console.error('Erreur :', error);
                alert("Une erreur s'est produite lors de l'enregistrement de l'objet.");
            }
            this.$emit('savedItem', this.localItem);
            this.$emit('loaded');
            this.closePopup();
        },
        closePopup() {
            this.$emit('close');
        }
    },
    created() {
        this.fetchInitialData();
    }
};
</script>

<style scoped>
.input-field.is-invalid {
    border-color: red;
}

.error-message {
    color: red;
    font-size: 12px;
    display: block;
    margin-top: -13px;
    margin-bottom: 15px;
}

H3 {
    margin: 0px;
    margin-bottom: 10px;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:1000
}

.popup-content {
    background-color: #fff;
    padding: 20px;
    width: 800px;
    border-radius: 8px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.item-details {
    flex: 1;
}

.divider {
    margin: 20px 0;
    border: 0;
    height: 1px;
    background: #ddd;
}

.form-section {
    margin-bottom: 20px;
}

.checkbox-field {
    /* width: 100%; */
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.input-field {
    display: block;
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Styles pour le champ de saisie automatique */
.autocomplete-list {
    border: 1px solid #ddd;
    border-radius: 4px;
    max-height: 150px;
    overflow-y: auto;
    position: absolute;
    background-color: white;
    width: 150px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
    margin-top: -15px;
}

.autocomplete-item {
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
}

.autocomplete-item:hover,
.highlighted {
    background-color: #e9f5ff;
    color: #007bff;
}

.btn-add,
.btn-save,
.btn-close,
.btn-edit,
.btn-remove {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
}

.btn-remove {
    background-color: #dc3545;
}

.btn-add:hover,
.btn-save:hover,
.btn-close:hover,
.btn-remove:hover {
    opacity: 0.9;
}

.actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
</style>