<template>

  <div>
    <!-- Contenu de la page -->
    <LoaderDiv :isLoading="loading" loaderText="Chargement en cours, veuillez patienter..." />
  </div>

  <div>
    <h2>Liste des alertes</h2>
    <div>Cette écran affiche la liste vos alertes à venir. </div>
    <div class="count">Vous avez {{ jobs.length }} alertes à venir</div>
    <table>
      <thead>
        <tr>
          <th width="200">Détail</th>
          <!-- <th>Liens</th> -->
          <!-- <th>Identifiant de l'objet</th> -->
          <th>Date et heure de la notification</th>
          <th>Autres suiveurs</th>
          <th>Statut</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="job in jobs" :key="job.job_id">
          <td>
            <a href="#" @click.prevent="showDetails(job.offer_id)"><img
                :src="cdnUrl + '/images/' + job.offer_id + '/' + job.offer_id + '_thumbnail.jpg'" width="50px"
                class="thumbnail">{{ job.offer_id }}</a><br><a target="_blank"
              :href="'https://page.auctions.yahoo.co.jp/jp/auction/' + job.offer_id">Yahoo !</a> / <a target="_blank"
              :href="'https://zenmarket.jp/fr/auction.aspx?itemCode=' + job.offer_id">Zenmarket</a>
          </td>
          <td>{{ new Date(job.notify_time).toLocaleString() }}</td>
          <td>
            <!-- Vérifiez si l'attribut existe et s'il contient le tableau -->
            <div v-if="job.followers && job.followers.person">
              <div v-for="(person, index) in job.followers.person" :key="index">
                {{ person }}
              </div>
            </div>

            <!-- Afficher un message si le tableau n'existe pas -->
            <div v-else>

            </div>
          </td>

          <td>{{ job.status }}</td>
          <td>
            <button @click="confirmCancel(job.job_id)">Annuler</button>
          </td>
        </tr>
      </tbody>
    </table>


    <OfferDetailPopup :isVisible="isModalVisible" @close="isModalVisible = false" :offer="selectedOffer"
      :loadingPopup="loadingPopup">
    </OfferDetailPopup>

    <!-- <ModalPopup :isVisible="isModalVisible" @close="isModalVisible = false" :images="selectedItem.images" :_key="selectedItem._key">
        <template v-if="loadingPopup">
          <p>Chargement...</p>
        </template>
<template v-else>
          <h2>{{ selectedItem.title }}</h2>
          <p><strong>Prix:</strong> {{ selectedItem.price }} €</p>
          <p><strong>Date de fin:</strong> {{ selectedItem.endtime }}</p>
          <p v-html="selectedItem.html_description || selectedItem.description"></p>
        </template>
</ModalPopup> -->
  </div>

</template>

<script>
import axios from 'axios';
import { isTokenExpired } from '../auth';
// import ModalPopup from './ModalPopup.vue';
import LoaderDiv from './LoaderDiv.vue';
import OfferDetailPopup from './offers/OfferDetailPopup.vue';
import config from '@/config/config.js';

export default {
  components: {
    LoaderDiv,
    // ModalPopup,
    OfferDetailPopup
  },
  name: 'JobsList',  // Ici, le nom du composant doit être multi-mots
  data() {
    return {
      jobs: [],
      loading: true,
      loadingPopup: false,
      selectedOffer: {},
      isModalVisible: false,
      cdnUrl: config.cdnUrl
    };
  },
  async created() {
    const token = localStorage.getItem('access_token');
    if (isTokenExpired(token)) {
      // Rediriger vers la page de login si le token est expiré
      this.$router.push('/');
    }

    try {
      // Activer le loader
      this.loading = true;

      const response = await axios.get(`${config.apiUrl}/jobs`);
      this.jobs = response.data;

    } catch (error) {
      console.error("Erreur lors de la récupération des jobs:", error);
    } finally {
      // Désactiver le loader
      this.loading = false;
    }
  },
  methods: {
    // Fonction pour confirmer l'annulation du job
    confirmCancel(jobId) {
      if (confirm(`Es-tu sûr de vouloir annuler le job ${jobId} ?`)) {
        this.cancelJob(jobId);
      }
    },
    // Fonction pour annuler un job
    async cancelJob(jobId) {
      const token = localStorage.getItem('access_token');
      try {
        await axios.post(`${config.apiUrl}/jobs/${jobId}/cancel`, {}, {
          headers: { Authorization: `Bearer ${token}` }
        });
        alert(`Le job ${jobId} a été annulé avec succès.`);
        // Met à jour la liste des jobs (optionnel, en fonction de ta logique de suppression)
        this.jobs = this.jobs.filter(job => job.job_id !== jobId);
      } catch (error) {
        console.error("Erreur lors de l'annulation du job:", error);
        alert("Erreur lors de l'annulation du job.");
      }
    },

    // Affiche les détails de l'annonce
    async showDetails(offer_id) {
      // Affiche le loader
      this.loadingPopup = true;
      this.isModalVisible = true;

      try {
        // Appel à l'API pour récupérer les détails de l'élément
        const response = await axios.get(`${config.apiUrl}/offers/${offer_id}`);

        // Met à jour selectedOffer avec les données récupérées de l'API
        this.selectedOffer = response.data;

      } catch (error) {
        console.error("Erreur lors de la récupération des détails de l'offre :", error);
      } finally {
        this.loadingPopup = false;
      }
    }
  }
};
</script>

<style scoped>
.count {
  margin-top: 10px;
  font-size: 11pt;
  font-weight: bold
}

.thumbnail {
  float: left;
  margin: 0px 15px 0px 0px;
  max-height: 37px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  /* background-color: #f2f2f2; */
  font-weight: normal;
  background-color: #3498db;
  color: white
}

button {
  padding: 6px 12px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #ff1a1a;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  text-align: center;
  font-size: 18px;
  color: #3498db;
}
</style>
