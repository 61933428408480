<template>
  <!-- <header>
      <img src="path/to/your-logo.png" alt="Logo" class="logo">
    </header>   -->
  <div class="app-layout">
    <!-- Sidebar fixe sur la gauche -->
    <Sidebar v-if="$route.meta.showSidebar" />

    <!-- Contenu principal scrollable à droite -->
    <div class="main-content" ref="mainContent" style="margin-left: 240px">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Sidebar from "./components/SidebarLeft.vue";
import router from "./router.js";

// Puis, dans votre fichier principal (main.js ou App.vue), ajoutez un watcher pour changer le titre
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Collection Manager by Prexens";
  next();
});

export default {
  components: {
    Sidebar,
  },
};
</script>

<style scoped>
.app-layout {
  display: flex;
  height: 100vh;
}

/* Sidebar fixe à gauche */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100vh;
  background-color: #2c3e50;
  padding: 20px;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1000;
}

/* Contenu principal, margé pour laisser de la place à la sidebar */
.main-content {
  margin-left: 200px;
  padding: 20px;
  height: 100vh;
  overflow-y: auto;
  /* Scroll vertical uniquement sur le contenu */
  /* background-color: #f9f9f9; */
  box-sizing: border-box;
  width: calc(100% - 200px);
}
</style>
