import config from "./config/config";
import axios from "axios";

export async function parse(offer) {
  try {
    if (offer.url === undefined) alert("L'url de cet offre n'est pas valide");
    else {
      await axios.get(
        `${config.apiUrl}/offers/parse?url=${offer.url}&notify=true`
      );
    }
  } catch (error) {
    console.error("Erreur lors de la l'actualisation de l'offre :", error);
  }
}
