<template>

  <div>
    <!-- Contenu de la page -->
    <LoaderDiv :isLoading="loading" />
  </div>

  <div>
    <h2>Ajouter un élément</h2>
    <div>Cette fonctionnalité permet d'ajouter l'url d'un élément à suivre. Et permet de déclencher une notification 5
      minutes avant la fin. </div>
    <form @submit.prevent="parseOffer">
      <!-- <h2>Informations</h2> -->

      <label>Url de l'élément à suivre:</label>
      <input v-model="url" type="text" />
      <button type="submit">Valider</button>
    </form>
    <p v-if="result">Result: {{ result }}</p>
  </div>

</template>

<script>
import axios from 'axios';
import config from './../../config/config.js';
import LoaderDiv from './../LoaderDiv.vue'; // Chemin vers votre composant Loader

export default {
  components: {
    LoaderDiv
  },
  data() {
    return {
      url: '',
      result: '',
      loading: false
    };
  },
  methods: {
    async parseOffer() {

      if (this.url === "") {
        alert("Merci de saisir une url valide");
        return;
      }


      this.loading = true;

      const token = localStorage.getItem('token');
      const response = await axios.get(`${config.apiUrl}/offers/parse?url=${this.url}&notify=true`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      this.result = response.data.result;

      this.url = "";

      this.loading = false;
    }
  }
};
</script>

<style scoped>
form {
  /*  max-width: 500px; */
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

form h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

form label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

form input {
  width: 100%;
  padding: 10px;
  padding-right: 0px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

form button {
  width: 100%;
  padding: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

form button:hover {
  background-color: #2980b9;
}
</style>